@font-face {
  font-family: "Muli";
  src: url("libs/purple-ui/assets/fonts/Muli.ttf") format("OpenType");
}

@font-face {
  font-family: "Mulish";
  src: url("libs/purple-ui/assets/fonts/Muli.ttf") format("OpenType");
}

@font-face {
  font-family: "Muli SemiBold";
  src: url("libs/purple-ui/assets/fonts/Muli-SemiBold.ttf") format("OpenType");
}

@font-face {
  font-family: "Muli Bold";
  src: url("libs/purple-ui/assets/fonts/Muli-Bold.ttf") format("OpenType");
}
