@import "../base/index";

.notification {
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  display: flex;
  align-self: center;
  z-index: 99999999 !important;

  left: 50%;
  top: 80px;
  transform: translateX(-50%) translateZ(100px);
  -webkit-transform: translateX(-50%) translateZ(1000000px);
  width: 500px;
  border-radius: 7px;
  max-width: calc(100% - 20px);

  @media (max-width: 799px) {
    max-width: calc(100% - 40px);
  }
}

.toast.show {
  z-index: 999999999 !important;
}
