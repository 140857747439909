$font-size-base: 14px;

// FONT-SIZE
.font-size-1 {
  font-size: $font-size-base;
}
.font-size-1 {
  font-size: $font-size-base * 1.1;
}
.font-size-2 {
  font-size: $font-size-base * 1.2;
}
.font-size-3 {
  font-size: $font-size-base * 1.3;
}
.font-size-4 {
  font-size: $font-size-base * 1.4;
}
.font-size-5 {
  font-size: $font-size-base * 1.5;
}
.font-size-6 {
  font-size: $font-size-base * 1.6;
}

// FONT-SIZE-SMALL
.font-size-small-1 {
  font-size: $font-size-base * 0.9;
}
.font-size-small-2 {
  font-size: $font-size-base * 0.8;
}
.font-size-small-3 {
  font-size: $font-size-base * 0.7;
}
.font-size-small-4 {
  font-size: $font-size-base * 0.6;
}
.font-size-small-5 {
  font-size: $font-size-base * 0.5;
}
.font-size-small-6 {
  font-size: $font-size-base * 0.4;
}

$border-radius-1: 3px;
$border-radius-2: 4px;
$border-radius-3: 8px;
$border-radius-50: 50%;
