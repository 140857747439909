@import url("libs/purple-ui/assets/fonts/index.css");

:root {
  --blue: #02e2bd;
  --green: #aff82d;
  --green_dark: #7fc837;
  --red: #eb5f5f;
  --gray: #8f9498;
  --purple: #4a0d77;
  --secundary: #d1b7e4;
  --black: #2c3033;
  --background: #f6f6f6;
  --sucess: #7fc837;
  --disable: #d6d6d6;
  --yellow: #f2c94c;
  --alertYellow: #ecc561;
  --info: #2d9cdb;
  --grayLight: #a7a7a7;
  --grayLight2: #d5d5d5;
  --grayLight3: #eeeeee;
  --redLight: #eb5f5f80;
  --lightPurple: #d1b7e4;
  --primary_1: #540dce;
  --primary_2: #6b57e0;
  --primary_3: #7869d4;
  --primary_4: #a599e3;
  --primary_5: #d1ccf0;
  --white: #f4f7fc;
}

html {
  font-family: "Muli";
}

button {
  font-family: "Muli";
  cursor: pointer;
}

fieldset {
  border: none;
}

body {
  font-family: "Muli";
}

#root {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

a:hover {
  text-decoration: none;
}

a {
  text-decoration: none;
}

option {
  font-family: "Muli";
}
span {
  font-family: "Muli";
}

::placeholder {
  font-family: "Muli";
  font-size: 0.875rem !important;
}

label {
  font-size: 0.875rem;
}

.cursor-pointer {
  cursor: pointer;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">>";
}

input {
  font-size: 0.875rem;
  font-family: "Muli" !important;
}
textarea {
  font-size: 0.875rem !important;
  font-family: "Muli" !important;
}

.hovered-underline:hover {
  text-decoration: underline;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  outline: 0;
}

div {
  font-family: "Muli";
}

.App {
  text-align: center;
}

.rccs__card--unknown > div {
  background: linear-gradient(321.03deg, #4a0d77 0%, #95ebdd 91.45%);
  box-shadow: none;
}

.rccs {
  margin: 0;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  width: 290px;
}

.rccs__card {
  height: 250px;
  margin: 0 auto;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  width: 400px;
}

.rccs__card--front,
.rccs__card--back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: linear-gradient(321.03deg, #4a0d77 0%, #95ebdd 91.45%);
  border-radius: 14.5px;
  color: #fff;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.rccs__number,
.rccs__name,
.rccs__expiry,
.rccs__cvc {
  opacity: 0.5;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  color: #000 !important;
}

.App-link {
  color: #61dafb;
}

#imgCol {
  background-size: 100% auto;
}

.react-datepicker__input-container input {
  color: gray;
  padding: 5px;
  border-radius: 5px;
  margin: 10px;
  border: 1px solid silver;
}

#btn-excel {
  color: white;
  height: 2rem;
  align-self: center;
  margin-left: 1rem;
}

@media only screen and (max-width: 1000px) {
  #imgCol {
    background-size: auto 100%;
  }
  #mainLoginDiv {
    background-color: black;
    padding: 1rem;
  }
}

/* ------------------- */
/* Google maps style */
/* ------------------- */
.map__ .gmnoprint,
.map__ .gm-style-cc,
.map__ .gm-control-active.gm-fullscreen-control {
  display: none;
}

/* ------------------ */
/* Editor Markdown */
/* ------------------ */
.closeer-markdown-editor .public-DraftEditorPlaceholder-root,
.closeer-markdown-editor .DraftEditor-editorContainer {
  font-size: 0.875rem;
}
.closeer-markdown-wrapper .rdw-link-modal {
  top: unset;
  bottom: -6px;
  left: -6px;
  height: max-content;
  max-width: 340px;
  min-width: 250px;
  width: 100%;
  min-height: 205px;
  border-radius: 8px;
  border: 1px solid #ededed;
  font-family: "Muli", sans-serif;
  box-shadow: 0px 3px 6px #00000029;
}
.closeer-markdown-wrapper .rdw-editor-toolbar {
  border: 1px solid #ededed;
  border-radius: 6px;
}
.closeer-markdown-wrapper .rdw-link-modal label {
  font-size: 0.875rem;
  margin: 0;
  margin-bottom: 0;
  font-family: "Muli Bold", sans-serif;
  color: var(--black);
}
.closeer-markdown-wrapper .rdw-link-modal .rdw-link-modal-target-option {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}
.closeer-markdown-wrapper .rdw-link-modal .rdw-link-modal-input {
  padding: 4px 0px 8px;
  height: auto;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.closeer-markdown-wrapper .rdw-link-modal .rdw-link-modal-buttonsection {
  font-size: 0.875rem;
}
.closeer-markdown-wrapper .rdw-link-modal .rdw-link-modal-btn {
  border-radius: 8px;
  font-family: "Muli Bold", sans-serif;
  transition: all 0.2s cubic-bezier(0.075, 0.32, 0.165, 1);
}
.closeer-markdown-wrapper .rdw-link-modal .rdw-link-modal-btn:hover {
  box-shadow: none;
}
.closeer-markdown-wrapper .rdw-link-modal .rdw-link-modal-btn:focus,
.closeer-markdown-wrapper .rdw-link-modal .rdw-link-modal-btn:active {
  box-shadow: none;
}
.closeer-markdown-wrapper
  .rdw-link-modal
  .rdw-link-modal-btn:first-child:not(:disabled) {
  background: var(--primary_1);
}
.closeer-markdown-wrapper .rdw-editor-toolbar .rdw-option-wrapper {
  border-radius: 50%;
  width: 1.7rem;
  height: 1.7rem;
  min-width: auto;
  min-height: auto;
  max-width: auto;
  max-height: auto;
  flex: none;
  transition: all 0.2s cubic-bezier(0.075, 0.32, 0.165, 1);
}
@media (max-width: 668px) {
  .closeer-markdown-wrapper .rdw-editor-toolbar .rdw-option-wrapper {
    width: 2rem;
    height: 2rem;
  }
}
.closeer-markdown-wrapper .rdw-editor-toolbar .rdw-option-wrapper:hover {
  box-shadow: none;
  border: 1px solid var(--primary_1);
}
.closeer-markdown-wrapper .rdw-editor-toolbar .rdw-option-wrapper:focus,
.closeer-markdown-wrapper .rdw-editor-toolbar .rdw-option-wrapper:active {
  background: var(--primary_1);
  border: 1px solid var(--primary_1);
  box-shadow: none;
}
.closeer-markdown-editor {
  transition: all 0.2s cubic-bezier(0.075, 0.32, 0.165, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  font-family: "Muli", sans-serif;
}
.closeer-markdown-editor * {
  font-size: 0.875rem;
  line-height: 1.098rem;
  color: var(--black);
}
.closeer-markdown-editor .public-DraftEditorPlaceholder-root * {
  color: #b4b6b6;
}
.closeer-markdown-editor .rdw-link-decorator-wrapper * {
  color: var(--primary_1);
  font-family: "Muli Bold", sans-serif !important;
  text-decoration: underline;
}
.closeer-markdown-editor:after {
  content: "";
  transition: all 0.2s cubic-bezier(0.075, 0.32, 0.165, 1);
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 0px;
  background: var(--black);
}
.closeer-markdown-editor:hover:after {
  height: 2px;
  transition: all 0.2s cubic-bezier(0.075, 0.32, 0.165, 1);
}
.closeer-markdown-wrapper.focused .closeer-markdown-editor:hover:after {
  height: 2px;
  background: var(--primary_1);
  transition: all 0.2s cubic-bezier(0.075, 0.32, 0.165, 1);
}
.closeer-markdown-wrapper.focused .closeer-markdown-editor:after {
  height: 1px;
  background: var(--primary_1);
  transition: all 0.2s cubic-bezier(0.075, 0.32, 0.165, 1);
}

#onesignal-slidedown-container
  #onesignal-slidedown-dialog
  .slidedown-button.primary {
  background: var(--primary_1) !important;
}

#onesignal-slidedown-container
  #onesignal-slidedown-dialog
  .primary.slidedown-button
  + .secondary.slidedown-button {
  color: var(--primary_1) !important;
}

#onesignal-slidedown-container
  #onesignal-slidedown-dialog
  .slidedown-body-message {
  font-family: "Muli", sans-serif;
  font-size: 1rem;
}

@media print {
  @page {
    size: A4;
    margin: 0mm;
    padding: 0mm;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    margin: 0;
    padding: 0;
    width: 210mm;
    height: 297mm;
    overflow: hidden;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .no-print,
  #no-print,
  .no-print > *,
  #no-print > *,
  .closeer-toast-notification,
  .closeer-toast-notification > *,
  .sonner__toast,
  .sonner__toast > * {
    display: none !important;
  }

  #datasheet-main {
    min-height: unset;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  #datasheet-header {
    padding: 4.23mm;
  }
  #datasheet-header > img {
    max-width: unset;
    max-height: unset;
    width: 26mm;
  }
  #datasheet-content {
    min-height: unset;
    height: unset;
    transform: scale(0.6);
    transform-origin: top center;
  }
  #datasheet-footer {
    transform: scale(0.7);
    transform-origin: center;
    padding: 0mm;
  }
}
