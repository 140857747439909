@import "base/index";
@import "components/reactSidebar";

* {
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  height: 100%;
  font-size: 1em;
}

@media (min-width: 300px) {
  html {
    font-size: 92%;
  }
}

@media (min-width: 500px) {
  html {
    font-size: 96%;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 100%;
  }
}

@media (min-width: 1800px) {
  html {
    font-size: 110%;
  }
}

.c-pointer {
  cursor: pointer;
}

.modal-source-global {
  padding-left: 0 !important;
}
.modal-source-global .modal-dialog-global-xl {
  @media (min-width: 576px) and (max-width: 992px) {
    max-width: 90%;
  }
}

#root,
:root {
  height: 100% !important;
  // font-size: 1rem;
  font-family: "Muli", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}
textarea {
  font: 500 1.6rem Poppins;
  color: var(--color-text-base);
}
h1 {
  font-size: 2.375rem;
}
h2 {
  font-size: 1.25rem;
}
h3 {
  font-size: 1.125rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 0.75rem;
}
h6 {
  font-size: 0.625rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Muli Bold", sans-serif;
}

th {
  font-family: "Muli Bold", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

p,
option,
li {
  font-size: 0.875rem;
  font-family: "Muli", sans-serif;
  margin: 0px;
}

label {
  color: $gray;
  font-size: 0.9rem;
}

table {
  font-size: 0.9rem !important;
}

.header a {
  text-decoration: none;
}
