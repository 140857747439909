@import "../variables/index";

.button {
  height: 35px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  text-decoration: none;
  align-self: flex-end;
  min-width: 200px;
  justify-content: center;
  align-items: center;

  svg,
  img {
    margin-top: 1px;
  }

  &__outline {
    &--green {
      border: 1px solid $black;
      background-color: $white;

      border: 1px solid $green;
      color: $green;

      &:hover {
        color: $white;
        background-color: $green;
      }
    }

    &--green-darker {
      border: 1px solid $black;
      background-color: $white;

      border: 1px solid $green-darker;
      color: $green-darker;

      &:hover {
        color: $white;
        background-color: $green-darker;
      }
    }

    &--red {
      border: 1px solid $red;
      color: $white;
      color: $red;
      background-color: $white;

      &:hover {
        border: 1px solid $red;
        color: $white;
        background-color: $red;
      }
    }

    &--black {
      border: 1px solid $black;
      background-color: $white;

      border: 1px solid $black;
      color: $black;

      &:hover {
        color: $white;
        background-color: $black;
      }
    }

    &--gray {
      border: 1px solid $gray;
      background-color: $white;

      border: 1px solid $gray;
      color: $gray;

      &:hover {
        color: $white;
        background-color: $gray;
      }
    }
  }

  &__fill {
    &--red {
      border: 1px solid $red;
      color: $white;
      background-color: $red;

      &:hover {
        border: 1px solid $red;
        color: $red;
        background-color: $white;
      }
    }

    &--green {
      border: 1px solid $green;
      color: $black;
      background-color: $green;

      &:hover {
        border: 1px solid $green;
        color: $black;
        background-color: $white;
      }
    }

    &--green-darker {
      border: 1px solid $green-darker;
      color: $black;
      background-color: $green-darker;

      &:hover {
        border: 1px solid $green-darker;
        color: $black;
        background-color: $white;
      }
    }

    &--black {
      color: $white;
      background-color: $black;
      border: 1px solid $black;

      &:hover {
        border: 1px solid $black;
        color: $black;
        background-color: $white;
      }
    }

    &--gray {
      border: 1px solid $white;
      background-color: $gray;

      border: 1px solid $white;
      color: $white;

      &:hover {
        color: $gray;
        background-color: $white;
      }
    }
  }

  &--sm {
    min-height: 25px;
    min-width: 25px;
    height: auto;
    width: auto;
    border-radius: 15px;
    font-size: 1rem;
  }

  &--md {
    min-height: 35px;
    min-width: 35px;
    height: auto;
    border-radius: 20px;
    font-size: 1.2rem;
  }

  &--lg {
    min-height: 45px;
    border-radius: 60px;
  }

  &--squared {
    border-radius: 4px;
  }

  &--circled {
    width: auto;
    height: auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.width-default {
    min-width: 200px;
    width: auto;
  }

  &.width-auto {
    min-width: auto;
    width: auto;
  }

  &.width-fill {
    min-width: 100%;
  }

  &.disabled {
    background-color: $gray-lighter;
    border: none;
    color: $gray;
  }
}
