.react-sidebar__sidebar {
  z-index: 5 !important;
}

.react-sidebar__overlay {
  z-index: 4 !important;
}

.react-sidebar__dragHandle {
  z-index: 4 !important;
}
