@import "./variables/index";

.card {
  padding: 20px;
  border-radius: $border-radius-2;
  background-color: $white;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  min-height: 300px;

  &.card__auto {
    min-height: auto;
  }
}

.card__fill {
  padding: 20px;
  border-radius: $border-radius-2;
  background-color: $white;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  height: 100%;
  min-height: calc(100vh - 70px);

  @media (max-width: 799px) {
    padding: 0.6rem;
  }
}

.preloader-img {
  animation: preloaderImg infinite 2s linear;
  height: 30px;
  margin: 120px 0;

  &--white {
    filter: invert(1);
  }
}

.preloader-img-input {
  animation: preloaderImg infinite 2s linear;
  height: 20px;
  filter: invert(0.4);

  &--white {
    filter: invert(1);
  }
}

// BORDER
.br-1 {
  border-radius: $border-radius-1;
}
.br-2 {
  border-radius: $border-radius-2;
}
.br-3 {
  border-radius: $border-radius-3;
}
.br-50 {
  border-radius: $border-radius-50;
}

// SHADOW
.shadow-1 {
  -webkit-box-shadow: 0px 0px 6px #00000029;
  -moz-box-shadow: 0px 0px 6px #00000029;
  box-shadow: 0px 0px 6px #00000029;
}

.google-places-autocomplete {
  ul.suggestions {
    list-style: none;
    margin-top: -40px;
    border: 1px solid lightgray;

    &.address {
      z-index: 15;
      background: #fff;
      position: relative;
      border: none;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }

    li {
      padding: 5px 10px;

      &:hover {
        cursor: pointer;
        background-color: $gray-lightest;
      }
    }
  }
}

.squared-badge {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  flex-direction: column;
  font-family: "Muli", sans-serif;
  margin: 0 5px;

  .small {
    font-size: 10px;
  }

  .large {
    font-size: 18px;
  }

  &__green {
    background-color: #5cb85c;
  }

  &__red {
    background-color: #d9534f;
  }

  &__gray {
    background-color: $gray-lighter;
  }
}

.profile_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 5px solid $white;
  -webkit-box-shadow: 0px 0px 6px #00000029;
  -moz-box-shadow: 0px 0px 6px #00000029;
  box-shadow: 0px 0px 6px #00000029;

  &:hover {
    cursor: pointer;
  }
}
.profile_image__circle {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 5px solid $white;
  -webkit-box-shadow: 0px 0px 6px #00000029;
  -moz-box-shadow: 0px 0px 6px #00000029;
  box-shadow: 0px 0px 6px #00000029;
}

@keyframes preloaderImg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.w-200 {
  width: 200px;
}

.fake-link {
  color: $gray;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hover-pointer {
  &:hover {
    * {
      cursor: pointer;
    }
  }
}

.favorite-wrapper {
  margin-right: 20px;

  svg {
    height: 25px;
    width: 25px;
  }
  &:hover {
    cursor: pointer;
  }
}

/* -------- SPLASH SCREEN ---------- */
.splash-screen-container {
  width: 100%;
  height: 100%;
  min-height: 80vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

/* ------ REPORTS TABLE CONTAINER -------- */
.container-overflow-styled {
  &::-webkit-scrollbar {
    width: 0.42rem;
    height: 0.42rem;
    border-radius: 0.42rem;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    width: 0.42rem;
    border-radius: 0.42rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #4A0D77;
    width: 0.42rem;
    border-radius: 0.42rem;
  }
}
