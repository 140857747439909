$white: #fff;
$gray-lightest: #dddddd;
$gray-lighter: #c0c5ce;
$gray-light: #a7adba;
$gray: #65737e;
$gray-dark: #485058;
$gray-darker: #343d46;
$black: #000;
$darken: #2c3033;

$green: #00ff00;
$green-dark: #00db00;
$green-darker: #5cb85c;

$red: #d9534f;

$background-base: #eeeeee;

$cyan-lightest: #ecfffb;
$cyan-lighter: #d1fdf4;
$cyan-light: #8fffe9;
$cyan: #00ffcd;
$cyan-dark: #00d3a9;
$cyan-darker: #009c7d;
$cyan-darkest: #006853;

$shadow-color: rgba(20, 20, 20, 0.38);

$translucent-black: rgba(0, 0, 0, 0.357);
